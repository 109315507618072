
a {
  text-decoration: none;
}

.divider {
  width: 100vw;
  padding-left: 20px
}

.exhibitor-item {
  font-size: 0.9rem;
  border-radius: 20px;
  position: relative !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;

  .locations {
    font-size: 0.9rem;

    span:not(:last-child)::after {
      content: ", ";
    }
  }
}

@media(max-width: 767px) {
  .exhibitor-item {
    border-radius: 0;
    //border-bottom: 1px solid lightgrey;
    box-shadow: none !important;

  }
}
